import React, {FC, JSX, useEffect, useState} from "react"
import Link from "next/link";
import {useSelector} from "react-redux";
import cls from "../customHeader.module.scss"
import {ICartItemType, IState} from "../../../../../types/state";
import {ICurrencies} from "../../MainLayout";
import {TranslationsType} from "../../../../../types/homePageTypes";
import {FailSvg} from "../../../../../svg";
import {useToast} from "../../../../../atoms/Toast/useToast";

interface ICustomCartDropDown {
    cart: { items: Array<ICartItemType>, grand_total: string; sub_total: string };
    items: Array<JSX.Element>;
    isCheckAllow: () => void;
    selectedRate: ICurrencies;
    translationsData: TranslationsType;
    allowCheckoutValue: string
}

const CustomCartDropDown: FC<ICustomCartDropDown> = (
    {
        cart,
        items,
        isCheckAllow,
        selectedRate: {symbol: selectedCurrSymbol} = {},
        translationsData,
        allowCheckoutValue
    }
) => {
    const toast = useToast()
    const classNameScrollHandle = {
        "true": cls["dropcart__products-list_scroll"],
        "false": cls["dropcart__products-list"]
    }

    const hasDownloadableItem = cart.items.some(item => item.type === 'downloadable');
    const signed = useSelector((state: IState) => state.customer.authenticated);

    const checkoutHrefHandle = () => {
        switch (signed) {
            case true:
                return "/checkout";
            case false:
                if (hasDownloadableItem || parseFloat(allowCheckoutValue) === 0) {
                    return "/signin"
                }
                return "/checkout"
            default:
                return "/checkout"
        }
    }

    const handleCheckoutClick = () => {
        if (hasDownloadableItem && !signed) {
            toast.open(
                <span className="d-flex faild-toast-fms">
                                        <FailSvg/>
                    {translationsData.ToastSignIn}
                                    </span>
            )
        }
    }

    return (
        <div className={cls.dropcart}>
            <div className={classNameScrollHandle[`${cart.items.length > 3}`]}>
                {items}
            </div>
            <div className={cls.dropcart__totals}>
                <table>
                    <tbody>
                    <tr>
                        <th>
                            {translationsData?.Total}{" "}
                        </th>
                        <td>
                            {cart.sub_total}
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
            <div className={cls.dropcart__buttons}>
                <Link href="/cart">
                    <a className={`btn btn-orange ${cls["rounded-pills"]}`}>
                        {translationsData?.Cart}
                    </a>
                </Link>
                <Link href={checkoutHrefHandle()}>
                    <a
                        className={`btn btn-orange ${cls["rounded-pills"]} ${cls["dropcart__buttons-link"]}`}
                        onClick={handleCheckoutClick}
                    >
                        {translationsData?.Checkout}{" "}
                    </a>
                </Link>
            </div>
        </div>
    )
};

export default CustomCartDropDown